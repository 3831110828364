.header-container .page-center,
.body-container .page-center,
.footer-container .page-center {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 20px;
    overflow: hidden;
    text-align: center;
}

.header-container-wrapper {
    flex: 0 0 auto;
}

.body-container-wrapper {
    flex: 1 0 auto;
}

.footer-container-wrapper {
    flex: 0 0 auto;
}

.body-container-wrapper .page-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: calc(100vh - 170px);
}

.body-container i.fa.fa-cloud {
    font-size: 100px;
    color: #2196f3;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.48);
}

.custom-header-bg {
    background: #001871;
    padding: 20px 0;
    font-family: 'Open Sans', sans-serif;
    box-shadow: 0 0 12px #000;
}

.custom-footer-bg {
    background: #001871;
    padding: 20px;
    text-align: center;
    color: #fff;
    box-shadow: 0 0 12px #000;
}

.logo {
    width: 30%;
    float: left;
    font-size: 38px;
    color: #ffffff;
    font-weight: 100;
    cursor: pointer;
    margin-top: 4px;
    white-space: nowrap;
}


#data-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 85%;
    font-size: small;
}

#data-table td,
#data-table th {
    border: 1px solid #ddd;
    padding: 5px;
}

#data-table tr:nth-child(even) {
    background-color: #f2f2f2;
}

#data-table tr:hover {
    background-color: #ddd;
}

#data-table th {
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: left;
    background-color: #001871;
    color: white;
}

.navigation {
    float: right;
    width: 70%;
}

.navigation ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: block;
    float: right;
}

.navigation ul li {
    display: inline-block;
}

.page-center h1 {
    white-space: nowrap;
}

.upload {
    background-color: #001871;
    background-image: 8121991;
    background-image: -webkit-linear-gradient(#001871 0%, #002ccd 100%);
    background-image: -moz-linear-gradient(#001871 0%, #002ccd 100%);
    background-image: -o-linear-gradient(#001871 0%, #002ccd 100%);
    background-image: linear-gradient(#001871 0%, #002ccd 100%);
    -webkit-box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 0 1px, rgba(52, 152, 219, 0.5) 0 0 5px;
    -moz-box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 0 1px, rgba(52, 152, 219, 0.5) 0 0 5px;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 0 1px, rgba(52, 152, 219, 0.5) 0 0 5px;
    -webkit-border-radius: 4px;
    -webkit-background-clip: padding-box;
    -moz-border-radius: 4px;
    -moz-background-clip: padding;
    border-radius: 4px;
    background-clip: padding-box;
    padding: 20px 20px;
    color: #fff;
    cursor: pointer;
    font-weight: 600;
    font-family: Open Sans;
    margin: 0.5rem !important;
}

.upload span {
    text-shadow: rgba(29, 111, 165, 0.9) 0 0 2px;
}

.upload span {
    padding: 20px 25px;
    font: 700 13px Montserrat, Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    color: #fff;
    -webkit-transition: all 350ms ease;
    -moz-transition: all 350ms ease;
    -o-transition: all 350ms ease;
    transition: all 350ms ease;
}

.upload:hover {
    background-color: #4aa3df;
    background-image: 8121991;
    background-image: -webkit-linear-gradient(#001871 0%, #002ccd 100%);
    background-image: -moz-linear-gradient(#001871 0%, #002ccd 100%);
    background-image: -o-linear-gradient(#001871 0%, #002ccd 100%);
    background-image: linear-gradient(#001871 0%, #002ccd 100%);
    -webkit-box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 0 1px, rgba(74, 163, 223, 0.5) 0 0 5px;
    -moz-box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 0 1px, rgba(74, 163, 223, 0.5) 0 0 5px;
    box-shadow: inset rgba(0, 0, 0, 0.2) 0 0 0 1px, rgba(74, 163, 223, 0.5) 0 0 5px;
}

.upload:hover span {
    text-shadow: rgba(33, 125, 187, 0.9) 0 0 2px;
}

.upload:active {
    background-color: #258cd1;
    background-image: 8121991;
    background-image: -webkit-linear-gradient(#002ccd 0%, #001871 100%);
    background-image: -moz-linear-gradient(#002ccd 0%, #001871 100%);
    background-image: -o-linear-gradient(#002ccd 0%, #001871 100%);
    background-image: linear-gradient(#002ccd 0%, #001871 100%);
}

.upload-hidden {
    display: none !important;
    visibility: hidden;
}

.upload {
    position: relative;
    overflow: hidden;
}

.upload:after {
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    transform: rotate(30deg);
    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(to right,
            rgba(255, 255, 255, 0.13) 0%,
            rgba(255, 255, 255, 0.13) 77%,
            rgba(255, 255, 255, 0.5) 92%,
            rgba(255, 255, 255, 0.0) 100%);
}

.upload:hover:after {
    opacity: 1;
    top: -30%;
    left: 100%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
}

.upload:active:after {
    opacity: 0;
}

.heading-1 {
    font-size: 3.2rem;
    margin-bottom: 0.5rem;
}

.heading-2 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
}

.contact-message {
    margin-top: 1rem;
}

.align-row-center {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.videoCam {
    width: 300px;
    border: 3px solid #001871;
    background: black;
}

.back-button {
    font-weight: 500;
}

.back-button:hover {
    cursor: pointer;
    font-weight: 700;
}

.loader {
    top: 42%;
    left: 42%;
    margin: auto;
    z-index: 1;
    position: absolute;
    border: 5px solid transparent;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
    border-top: 5px solid #001871;
    border-right: 5px solid #001871;
    border-bottom: 5px solid #001871;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}


@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.scanOverlay {
    top: 0px;
    left: 0px;
    z-index: 1;
    box-sizing: border-box;
    border: 10px solid transparent;
    position: absolute;
}

@media (max-width:700px) {
    .custom-header-bg {
        text-align: center;
    }

    .heading-1 {
        font-size: 2rem;
    }

    .heading-2 {
        font-size: 1rem;
    }

    .videoCam {
        width: 250px;
    }

    .logo {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }

    .navigation ul {
        float: none;
        text-align: center;
    }

    .navigation ul li+li {
        margin-top: 10px;
    }

    .navigation {
        float: none;
        width: 100%;
    }

    .body-container-wrapper .page-center {
        height: calc(100vh - 200px);
    }

}

@media (max-width:426px) {

    .heading-1 {
        font-size: 2rem;
        margin-bottom: 5px;
    }

    .heading-2 {
        font-size: 1rem;
        margin-bottom: 5px;
    }

    .contact-message {
        margin-top: 0;
    }

    .page-center h1 {
        font-size: 0.9rem;
        margin-bottom: 20px;
    }

    .body-container-wrapper .page-center {
        height: calc(100vh - 270px);
    }

}

@media (min-width:426px) and (max-width:768px) {
    .page-center .logo {
        display: block;
        text-align: center;
        float: none;
    }

}


@media (max-width:335px) {
    .page-center h1 {
        font-size: 98%;
    }

    .body-container-wrapper .page-center {
        height: calc(100vh - 270px);
    }

    .heading-1 {
        font-size: 1.5rem;
    }

    .heading-2 {
        font-size: 0.9rem;
    }

    .upload span {
        padding: 0;
        font: 700 9px Montserrat, Helvetica, Arial, sans-serif;
    }
}

@media (max-width:320px) {
    .page-center h1 {
        font-size: 98%;
    }

    .body-container-wrapper .page-center {
        height: calc(100vh - 320px);
    }
}